import './Footer.css';
import { IonFooter,  IonToolbar, IonGrid, IonRow, IonCol  } from '@ionic/react';

interface ContainerProps { }

const Footer: React.FC<ContainerProps> = () => {
  return (
    <IonFooter className="ion-no-border" id="footer" translucent={true}>
    
      <IonToolbar className="myfooter footer-grad" style={{textAlign: "left"}}>

      <IonGrid>
      <IonRow>
        <IonCol size="4" style={{textAlign: "right"}}>
         <a href="/" className="footer-link">משחק דפדפן</a>
         
           <a href="http://proplay.co" className="footer-link">פרופליי</a>
        </IonCol>
        <IonCol size="6" style={{fontSize: "10pt", textAlign: "right"}}>     
            כל הזכויות שמורות איזרהסולג׳רס 2006-2024
        </IonCol>
      </IonRow>
      </IonGrid>

      

      </IonToolbar>
    </IonFooter>
  );
};

export default Footer;
