import { IonMenu,IonCard,IonSlides, IonSlide, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonModal, IonImg, IonContent, IonHeader, IonRow, IonGrid, IonFooter, IonPage,IonButtons, IonIcon, IonCol, IonTitle, IonToolbar, IonButton, IonItem, IonLabel, IonList, IonListHeader, IonMenuButton } from '@ionic/react';

import React, { useState } from 'react';
import './Rules.css';


const Rules: React.FC = () => {

  return (
    <IonPage>


<IonContent fullscreen={false} class="background mycon">
    <IonGrid>
          <IonRow>
              <IonCol size="6" style={{textAlign: "right", color:"white", fontSize: "18px", backgroundColor: "black", marginTop: "100px"}}>
              


<table className="stable">
    <tr>
        <td></td>
        <td>
          <h2>  מדריך לאיזרה </h2>
            </td>
    </tr>
    <tr>
        <td>בסיס</td>
        <td>בכל חצי שעה מתבצע עדכון במשחק, התהליכים שמתבצעים בעדכון: קבלת 3
            תורות לכל צבא קבלת אוכלוסיה לא מאומנת בהתאם לרמת הפיתוח תפוקת העבדים
            (קבלת המשאבים) קבלת 1 מנה עדכון הדירוג בעיר והדירוג הכללי</td>
    </tr>


    <tr>
        <td>דירוג</td>
        <td>הדירוג מתעדכן בכל חצי שעה, הדירוג נקבע לפי עוצמת ההתקפה, ההגנה,
            הריגול והסיור, וכל זה ביחס לשאר הצבאות, כמובן חשוב להשקיע בכל הכוחות
            באותה מידה על מנת להעלות את הדירוג.<br /> כיצד להקים שבט או להצטרף לשבט?
            100 המדורגים הראשונים בכל עיר יכולים בכל שלב לפתוח שבט משלהם, האחרים
            יכולים דרך הבסיס להיכנס לעמוד המדרג את כל השבטים, ולשלוח בקשת
            הצטרפות, וכאשר היא תאושר המשתמש יצורף לשבט.<br /></td>
    </tr>


    <tr>

        <td>התקפה ותורות
        </td>
        <td>בדף זה רשומים לפניך כל הצבאות המדורגים, אתה בוחר את מי לתקוף וכיצד
            לפעול, על מנת שיהיה לך סיכוי כלשהו מומלץ לתקוף משתמשים שפחות או יותר
            שווי כח אליך, כך התוצאות יהיו טובות יותר ויעזרו לך להתפתח.<br /> לכל קרב
            נדרשת כמות מסוימת של אוכל לחיילים על מנת שיוכלו לצאת לקרב, הכמות
            רשומה מעל כמות התורות לבחירה.<br /><br /> במידה ויגמר לך האוכל, לא תוכל לתקוף
            ותיאלץ לחכות לעדכון ולבדוק את מצב האיכרים.<br /> בהתקפות תוכל לשעבד חיילים
            של צבאות אחרים ולהפוך אותם לעבדים שלך, אם תתקוף את אותו צבא מעל 5
            פעמים, בפעם השישית כבר לא תוכל להרוג או לשעבד לו חיילים, אך בכל זאת
            תוכל לשדוד את המשאבים שלו.<br /><br /> זמן המתנה בין תקיפה לתקיפה הינו 5 שניות
            לפחות.<br /> כמו כן, תוכל לצפות בהישגים של כל צבא, כאשר תכנס לעמוד התקיפה
            שלו.<br /><br /> תורות בזמן התקיפה, תאלץ לבחור את כמות התורת להתקפה, ככל שתשתמש
            ביותר תורות כך הנזק לאויב יהיה גדול יותר, בכל קרב יכולים להיהרג גם לך
            חיילים וגם לאויב, במידה וניצחת תוכל לשעבד לו חיילים ולהפוך אותם
            לעבדים שלך, וגם לזכות בכמות מכובדת של זהב , אוכל, ברזל, ועץ שתעזור לך
            להתפתח.<br /></td>

    </tr>

    <tr>
        <td>ערים</td>
        <td>ישנן 5 ערים, אליסר, בנטדורוס, דרקלנד, גרנדרסיטי, נירוקוור.<br /> כל צבא
            יכול לתקוף רק צבאות מהעיר שלו! למה שווה לעבור עיר? כשאתם עוברים עיר
            אתם מקבלים הרבה יותר משאבים! לדוגמא אם עלית לעיר השניה, אתה מקבל פי 2
            משאבים מהעיר הראשונה.<br /> בעיר השלישית, תקבל פי 3 משאבים מבעיר הראשונה,
            בעיר האחרונה, תקבל פי 5 מהעיר הראשונה.<br /> כמו כן, בערים הרחוקות תוכלו
            להילחם עם צבאות רציניים יותר וחזקים יותר ולהתקדם מהר יותר במשחק.<br /></td>

    </tr>


    <tr>
        <td>ריגול</td>
        <td>הריגול זהו קלף חזק מאוד ומשמעותי! לא תמיד צריך למהר לבזבז 10 תורות
            כאשר בעזרת המרגלים תוכל להוציא מידע רלוונטי, להשוות כוחות ולנקוט
            צעדים בהתאם.<br /> ככל שעוצמת הריגול תהיה חזקה יותר, כך יגדל הסיכוי לחדור
            לשורות האויב.<br /> בריגול תוכל לראות את כל נתוני האוייב, את תוקף הקסמים
            שלו, קח בחשבון שכמה מרגלים גם יכולים להיתפס.<br /></td>

    </tr>


    <tr>
        <td>שבט</td>
        <td>שבט: השבט הוא איחוד של כמה צבאות, לכל שבט יש ראש שבט, שהוא מנהל את
            כל הצוות, ולכל שבט יש סגן מנהל השבט, גם הסגן וגם המנהל עצמו יכולים
            לשלוח הזמנות הצטרפות לשבט.<br /> ישנו גם הימנון לכל שבט אותו קובעים הסגן
            והמנהל, הימנון זה מופיע אצל כל חברי השבט בדף ההתקפה שלהם.<br /><br /> המיסים הם
            החלטה של ראש השבט, והמיסים הולכים אל ראש השבט ישירות, <br />

            ישנה הגבלה על המיסים לפי הערים בהתאמה: (25K, 100K, 1M,10M,100M)
            <br />
            את המיסים כל שחקן משלם פעם אחת ביום באופן ידני, ראש השבט יוכל
            לראות מי שילם מיסים ומי לא, ראש השבט וסגנו פטורים מתשלום המיסים.<br /> רמת
            ומוניטין השבט לכל שבט יש רמה, ככל שהרמה תהיה גבוהה יותר, כך השבט
            יעזור יותר בקרבות של חברי השבט.<br /><br /> כל התקפה של חבר בשבט, וכל הישג שחבר
            השבט מקבל, מוסיפה לאותו חבר מוניטין (תלוי בתוצאות ההתקפה) ואותן
            נקודות גם מתווספות לכל השבט, מה שמקרב את השבט לרמה הבאה.<br /> כמו כן, ניתן
            לראות את המוניטין באחוזים של כל חבר בשבט ביחס לחברי השבט האחרים.<br />
            במעבר שבט, רק הנקודות שהשגת בעזרת ההישגים תמיד ישארו איתך! כל שבט
            יכול להכיל עד 25 צבאות, כמו כן, שבט הוא ייחודי לעיר מסויימת, אם מישהו
            רוצה לעבור עיר, יצטרך לנטוש את השבט כמו כן, ראש השבט וסגנו יכולים
            לסמן צבאות להם יינתן פטור מלא מתשלום מיסים.<br /> דירוג השבט הוא לפי ממוצע
            כל העוצמות (התקפה הגנה ריגול וסיור) של כל צבאות השבט.<br /> למה שווה להיות
            בשבט? בכל התקפה שלך והתקפה על חבריך לשבט, השבט יעזור! זה לא אומר
            שכולם יילחמו ביחד תמיד, אבל כמות מסויימת של חיילים מכל חברי השבט תבוא
            לעזור בקרב, ותראה זאת בתוצאות הקרב.<br /></td>

    </tr>
    <tr>


        <td>אימון</td>
        <td>בכל עדכון, תקבל כמות אזרחים לא מאומנים לפי רמת הפיתוח.<br /> בעמוד
            הפיתוח תוכל לפתח את קבלת האזרחים לעדכון.<br /> לדוגמא: קיבלת 10 אזרחים לא
            מאומנים היום, אתה בוחר למה להפוך את האזרחים האלה, לאמן אותם להיות
            חיילים? מרגלים? סיירים? איכרים? עבדים? או פרשים? כל אימון לכל אזרח
            כרוך בעלות זהב, כמות הזהב הנדרשת רשומה ליד כל סוג אימון, גם ניתן
            לראות את כמות האזרחים שיש לך בכל קטגוריה.<br /> פרשים: הפרשים כמו החיילים
            הם משתתפים בקרב, הם חזקים פי 1.<br />2 מאשר חייל רגיל, אך אינם יכול להיהרג
            בקרבות, ניתן לאמן פרש אחד על כל 10 חיילים, ז"א אם ברשותך 11 חיילים
            תוכל לאמן פרש אחד.<br /> אימון מתקדם: זהו אימון של חיילים קיימים, ללמד להגן
            טוב יותר, טכניקות התקפה, וכו'.<br /><br /> כל פיתוח שכזה תורם רבות לקרב
            ולמיומנות חיילך.<br /> כמות הזהב הנדרשת לצורך האימון רשומה בכפתור הפיתוח.<br />
            פיתוח: לכל העבדים שלך יש תפקיד (פירוט בהמשך) להביא זהב, עץ, ברזל, גם
            לאיכרים יש עבודה, להביא אוכל! כל איכר מביא כמות מסויימת של אוכל,
            לדוגמא: יש לך 3 איכרים, אז כל איכר מביא בין 5 ל 7 אוכל בכל חצי שעה,
            אבל אתה מסתפק ב5-7 אוכל לאיכר? לכן צריך לפתח את כמות הבאת המשאבים.<br />
            ניתן לפתח את כל סוגי העבודה, הבאת מזון,עץ,ברזל,זהב.<br /><br /> פיתוח נוסף הוא
            פיתוח הבסיס, זוכר את פס הכוח? שהוא החיים של הבסיס שלך? אז ניתן להעלות
            את הרף על מנת שלאויבינו יהיה קשה יותר לשבור אותנו, בכל קטגוריית פיתוח
            רשומה כמות המשאבים הנדרשים לאותו פיתוח, וודא שיש לך את הסכומים
            והמשאבים לפיתוח זה.<br /> כמו כן, ניתן לפתח את אפשרות קבלת האולוסיה לעדכון
            כמו שפורט למעלה.<br /></td>

    </tr>





    <tr>
        <td>חנות</td>
        <td>קניית נשקים: החנות זהו המקום בו אתה רוכש את כל הציוד לחיילים
            למרגלים ולסיירים, כגון: נשקים להתקפה ,להגנה וכו', על מנת לקנות נשק,
            צריך שתהיה ברשותך כמות המשאבים הנדרשת.<br /> כמו כן, תוכל לראות כמה נשקים
            יש לך מכל סוג ברשימה.<br /> מכירה: תוכל גם למכור נשקים ישנים שאתה לא זקוק
            להם, לדוגמא: קנית גרזן בשווי 1000 זהב, 1000 עץ ו 1000 ברזל, אם תמכור
            אותו תקבל 200 עץ ו200 ברזל ו200 אוכל.<br /></td>
    </tr>




    <tr>
        <td>מכרה<br />ושדות
        </td>
        <td>זהו מרכז המשאבים שלך, כל ייבוא הזהב שלך, האוכל, העץ והברזל הוא פה,
            בכל פעם שאתה שולח אזרחים להיות עבדים, הם עבדים משוחררים, חובה וכמה
            שיותר מהר לשלוח אותם לעבודה, בה אתה זקוק להם יותר.<br /> לדוגמא: יש לך 10
            עבדים ללא תעסוקה, תוכל לשלוח 3 למכרה הזהב, 3 למכרה הברזל ו4 לכריתת
            עצים, תוכל גם להעביר עבדים ממקום למקום, רק תקיש את מספר העבדים להוציא
            ולחץ על "הוצא עבדים", עבדים אלו יהפכו לעבדים ללא תעסוקה ותוכלו לשלוח
            אותם מחדש.<br /> תוכל גם לראות מידע רלוונטי כגון: כמה מכל משאב הביאו העבדים
            בחצי שעה האחרונה, העבדים מביאים את הזהב, ברזל והעץ, והאיכרים אחראים
            למזון.<br /></td>
    </tr>



    <tr>
        <td>בנק</td>
        <td>הבנק הוא מרכז החיסכון שלך, תוכלו להפקיד בו 50 אחוז מכמות הזהב שלך
            פעמיים ביום, ככה לאט לאט יצטבר לך סכום משמעותי, השתמש בבנק בחכמה,
            ותוכל להגיע לגדולות במשחק.<br /> בבנק תוכל לראות מתי מתקיים עדכון הבנק, בו
            תקבל את הריבית, ואם יש לך פחות מ2 הפקדות, יהיו לך 2 הפקדות לאחר
            העדכון.<br /></td>
    </tr>



    <tr>
        <td>כללי</td>
        <td>כל עונה נמשכת כ90 יום, לאחריה כולם יתחילו מההתחלה ולכולם תהיה
            הזדמנות להוביל.<br /> אך ישנם דברים שלא יתחילו מחדש ויישמרו אצלכם לתמיד,
            ואלו הם הגיבורים שהשגתם, וההישגים! בסיום העונה, 20 הצבאות המובילים,
            ו5 השבטים המובילים ייכנסו להיכל התהילה הנצחי! כמו כן, ב Proplay ID
            שלכם יישמר הדירוג שלכם בכל עונה, וכולם יוכלו לראות את הנתונים האלה.<br />
            </td>
    </tr>
    </table>





              </IonCol>
          </IonRow>
    </IonGrid>
    </IonContent>

      
    </IonPage>
  );
};

export default Rules;
