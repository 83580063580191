
import { IonMenu,IonCard,IonSlides, IonSlide, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonModal, IonImg, IonContent, IonHeader, IonRow, IonGrid, IonFooter, IonPage,IonButtons, IonIcon, IonCol, IonTitle, IonToolbar, IonButton, IonItem, IonLabel, IonList, IonListHeader, IonMenuButton } from '@ionic/react';
import { useState } from 'react';
import LoginModal from '../components/LoginModal';
import './Header.css';

interface ContainerProps { }


const Header: React.FC<ContainerProps> = () => {

  const [showModal, setShowModal] = useState(false);

  return (
    <div>
    <IonHeader className="ion-no-border ion-padding-top header-grad" translucent={true}>
      <IonListHeader className="ion-padding-right">
      <IonLabel><a href="/home"><IonImg style={{height: "65px", width: "165px"}} class="logo" src="assets/images/logo2.png"/></a></IonLabel>
      <IonButton color="warning" href="/story">הסיפור המלא</IonButton>
      <IonButton color="warning" href="/rules">הוראות</IonButton>
      <IonButton color="warning" href="http://s1.izra.co.il/halloffame">היכל התהילה</IonButton>
      <IonButton color="warning" href="http://s1.izra.co.il/support">תמיכה</IonButton>
      <IonButton color="warning" fill="solid" onClick={() => setShowModal(true)}>התחבר</IonButton>
      <IonButton></IonButton>
      </IonListHeader>
    </IonHeader>

    <LoginModal show={showModal} setShow={setShowModal}></LoginModal>
    </div>
  );
};

export default Header;
