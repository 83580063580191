import './LoginModal.css';

import { IonMenu,IonCard,IonSlides, IonSlide, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonModal, IonImg, IonContent, IonHeader, IonRow, IonGrid, IonFooter, IonPage,IonButtons, IonIcon, IonCol, IonTitle, IonToolbar, IonButton, IonItem, IonLabel, IonList, IonListHeader, IonMenuButton } from '@ionic/react';
import { personCircle, search, star, ellipsisHorizontal, ellipsisVertical,pin } from 'ionicons/icons';
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
//import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login'
import { useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import { makeAutoObservable } from "mobx"
import { observer } from "mobx-react-lite"
import modalManager from './ModalStateManager'

interface ModalProps {
    show: boolean,
    setShow: Function
}


const LoginModal: React.FC<ModalProps> = (props) => {


  var { show, setShow } = props;


  const responseGoogle = (response: any) => {
        
    if(response.tokenId != undefined){

      window.location.href = "https://the.izra.co.il/login/google?token="+ response.tokenId + "&redirect=true";

      // fetch("https://the.izra.co.il/login/google?token="+ response.tokenId)
      //   .then(res => res.json())
      //   .then((result) => {
      //     console.log(result)
      //     window.location.href = result.redirect;
      //   });
    } else {
      console.log(response);
    }
  }


  const responseFacebook = (response: any) => {


    if(response.accessToken != undefined){
      
      window.location.href = "https://the.izra.co.il/login/facebook/?token="+ response.accessToken + "&redirect=true";
      // fetch("https://the.izra.co.il/login/facebook/?token="+ response.accessToken)
      //   .then(res => res.json())
      //   .then((result) => {
      //     console.log(result)
      //     window.location.href = result.redirect;
      //   });
    } else {
      console.log(response);
    }
  }

  return (
    <IonModal
      isOpen={show}
      cssClass='login-modal header-grad'
      swipeToClose={true}
      presentingElement={undefined}
      onDidDismiss={() => setShow(false)}>

    <div style={{display: "flex", justifyContent: "center", paddingTop: "30px"}}>
      <IonImg style={{height: "65px", width: "165px"}} class="logo" src="assets/images/logo2.png"/>
    </div>
    <p style={{color: "white", height: "40px", marginTop:"0px", paddingTop: "10px",textAlign: "center", fontSize: "25px"}}>הרשמה והתחברות</p>


      <div style={{textAlign: "center"}}>
            
            <br />
     
               <FacebookLogin
                appId="403423396349429"
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                cssClass="fb-login-btn"
                textButton="התחבר עם פייסבוק"
                icon="fa-facebook"
              />
    <br /><br />

    

<GoogleLogin
    clientId="706672370313-387a2mqhpsk4r0io9nhl16rf56dv7330.apps.googleusercontent.com"
    render={renderProps => (
      <GoogleLoginButton onClick={renderProps.onClick} text="התחבר עם גוגל" align="right" style={{width: "250px", height: "40px", display: "inline"}}/>
    )}
    buttonText="Login"
    onSuccess={responseGoogle}
    onFailure={responseGoogle}
    cookiePolicy={'single_host_origin'}
  />


        
  
  <br/><br/>
  
  <a className="regular-login" href="http://s1.izra.co.il/login">התחבר באמצעות אימייל וסיסמא</a>
  
  <br/><br/><br/>
      </div>
    </IonModal>
  );
};

export default LoginModal;
