import { IonMenu,IonCard,IonSlides, IonSlide, IonCardHeader, IonCardTitle, useIonViewDidEnter, withIonLifeCycle, IonCardSubtitle, IonCardContent, IonModal, IonImg, IonContent, IonHeader, IonRow, IonGrid, IonFooter, IonPage,IonButtons, IonIcon, IonCol, IonTitle, IonToolbar, IonButton, IonItem, IonLabel, IonList, IonListHeader, IonMenuButton } from '@ionic/react';
import { personCircle, search, star, ellipsisHorizontal, ellipsisVertical,pin } from 'ionicons/icons';
//import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login'
import React, { useEffect, useState } from 'react';
import LoginModal from '../components/LoginModal';
import './Home.css';


const Home: React.FC = () => {

  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(String);
  
  
  const images = ["assets/images/orc.png","assets/images/elf.png"];
 

  return (
    <IonPage>




<IonContent class="background">
    <IonGrid>
          <IonRow>
              <IonCol style={{textAlign: "center", color:"white"}}>
               <span style={{ fontSize: "26pt", fontWeight: "bold"}}>איזרהסולג׳רס</span>
               <br/><br/>
               <span style={{ fontSize: "16pt"}}>
                בנה את הצבא החזק ביותר
                </span>
                <br/><br/>
                <IonButton size="large" color="warning" style={{fontWeight: "bold"}} onClick={() => setShowModal(true)}>התחל עכשיו</IonButton>
              </IonCol>
          </IonRow>
    </IonGrid>
    </IonContent>

  
    <IonImg className="race-left" src={images[0]}/>
    <IonImg className="race-right" src={images[1]}/>

    <LoginModal show={showModal} setShow={setShowModal}></LoginModal>
      
    </IonPage>
  );
};

export default Home;

