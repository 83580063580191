import { IonMenu,IonCard,IonSlides, IonSlide, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonModal, IonImg, IonContent, IonHeader, IonRow, IonGrid, IonFooter, IonPage,IonButtons, IonIcon, IonCol, IonTitle, IonToolbar, IonButton, IonItem, IonLabel, IonList, IonListHeader, IonMenuButton } from '@ionic/react';

import React, { useState } from 'react';
import './Story.css';


const Story: React.FC = () => {

  return (
    <IonPage>


<IonContent fullscreen={false} class="background mycon">
    <IonGrid>
          <IonRow>
              <IonCol size="6" style={{textAlign: "right", color:"white", fontSize: "18px", backggroundColor: "black"}}>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            קרוב ל-174 שנים לאחר פרוץ המלחמה, <br />
            החיים על פני האדמה עמדו בפני הכחדה כוללת. <br />
            ילדים נולדים לתוך רעש תופי מלחמה, בעוד צל כבד מכביד על כל יושבי ארץ "טרונבאון" ועד ארץ "עצי השחור".<br />
             עת, עת נמוגה לה התקווה ואיתה רוחות השלום.<br />
              יהיה זכר מלכי השלום כזכר הבריתות בין הגזעים. <br />
              אדם הורג אדם, אורק נופל תחת חרבו של אורק, אש אוכלת אש וצל המלחמה מרחף מעל הארץ.<br />
               הרוע סורר באוויר, האדמה רועדת, השמים ממטירים אש וחיילים לא מפסיקים ליפול. האלפים,<br />
                בני האלמוות שכל ימי חייהם היו עטופים בכיפת הגנה תחת עצי היערות לא חמקו מן הבלתי נמנע - הגורל.<br />
                 אדימרדנאוס, מלך "יערות טרונבאון", ראש מלכי האלפים, נפל בשדות הטרונבאון לאחר שהאש אכלה את גופו ואיתו את תקוות בני האלמוות. <br />
                 לא נותר לאלפים דבר, חוץ מאשר גוש המתכת שנראה לא אחר מגרזן, התקוע בלבו של אדימרדנאוס.<br />
                  בני האלמוות, שכל מטרתם מאז נפילת מלכם הייתה למצוא את בעליו של הגרזן, הזניחו בדרכם את האהבה, השלווה ואת דרכי האושר אשר לימד אותם אדימרדנאוס, ורק השנאה, מילאה את גופם ורוחם.<br />
                   לא עבר זמן רב מאז האשימו האלפים את הגמדים בהריגת מלכם.<br />
                    רוחות המלחמה נשמעו מן המזרח ופניהם דרומה, שנאה גדולה מילאה את לבם של האלפים.<br />
                     על כך שילמו מחיר כבד כשצבא האורקים הכה, ואחרון מלכי בני האלמוות נפל.<br />
                      צבא האלפים נקרע. הגמדים מכים, האורקים הורגים ובני האלמוות מתים בזה אחר זה. <br />
                      כס המלכות נותר שומם וספוג בדם המלחמה. אך אלה לא היו בריתות השלום היחידות שנופצו למשמע קולות המלחמה.<br />
                       ערפדי זרואונום, גזע קדום לגזע בני האדם, שחמדתם ושאיפתם לכוח הפילה אותם לעבר שערי השאול, חמדו שנית.<br />
                        חודש וחצי מיום נפילתם של האלפים, מצאה עצמה אנתדרסיה, נסיכת בני הלילית, כעלה באש הגיהינום האורקית.<br />
                         על מתנה יותר טובה מזו לא יכלו הערפדים לחלום - דם מלכים. <br />
                         רוח חמדתו של ג'ימניז, מנהיג זרואונום, עלתה. רוחות המלחמה לא נדמו, אש גיהינום שרפה את הארץ ודם יצורי השאול נשפך תחת רגליהם של יצורי הלילה.<br />
                          רעש תופי המלחמה תחת שברי הבריתות התחזק, ודם נוסף נשפך על הארץ. בני האדם עודפים את צבא האורקים, גמדים נסוגים ממטר חיצי האלפים, אורקים נרמסים תחת רגליהם של יצורי הלילה, בני הלילית פולשים אל ארץ השאול והארץ אינה מזילה דמעה עוד.<br />
                           אנשי הזאב, שעד אותו הזמן לא ראו דם במלחמה, לא חמקו מאויביהם המושבעים - הערפדים. <br />
                           לאחר שבירת הבריתות הבינו מנהיגי הערפדים כי אין מה להפסיד, ויש לחסל את אויביהם אנשי הזאב. אך המלחמה לא הייתה קלה. האורקים הכו בצד אחד ואנשי הזאב בצד השני, זמן רב נשפך הדם על הארץ וחרב הערפדים לא נשברה. <br />
                           הערפדים, מבוצרים תחת חומות כפולות עטופות בערפל סמיך, מקווים לנס, ואכן צבא האדם נתקל באנשי הזאב.<br />
                            צרחות הכאב נשמעו לאורך כל הארץ, דם נשפך כנהר עלי אדמות וחיילים נפלו כמו דשא הנאכל באש.<br />
                             ששת הצבאות נקרעו לגזרים, מלכים רבים איבדו את שפיותם במלחמה, רבים גם מתו, חיילים מפוזרים בשדות הקרב ללא מנהיג שיוביל אותם.<br />
                              ששת הצבאות שילמו מחיר כבד במלחמה, כמעט ולא נותרה נפש חיה על האדמה.<br />
                               הכאוס שלט על הארץ זמן רב. דם אחים זרם כנהר, רק רוחות השלום אינן. האגדה מספרת שיבוא מנהיג חזק, שיאחד את הצבאות הנותרים ויבנה בריתות שלום תחת אור שמש. <br />
                               אדם ואורק יאחזו יחדיו בחרב, מטר השמים יגבר על אש הגיהינום והארץ תשוב לחיות. <br />
                               הדם הרב שישפך עד אותם הימים, יהפוך לצמחיה. בני הלילה ובני האור יוכלו שוב לחיות יחדיו, כנגזר עליהם בראשית הימים.<br />
                                הדרך תהיה קשה וארוכה, אך בסוף המלחמה האין סופית, יוצב מלך אחד, שישלוט על הארץ כולה. <br />
                                נשימת לוחם ומנהיג קיימת בכל אחד ואחד מאיתנו, רק צריך להוציא אותה החוצה...<br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

              </IonCol>
          </IonRow>
    </IonGrid>
    </IonContent>

      
    </IonPage>
  );
};

export default Story;
